.ReactModal__Content {
    top: 75px !important;
}

.react-confirm-alert-overlay .react-confirm-alert {
    border: none !important;
}

select.form-control {
    padding: 0px 10px;
}
