table.table {
    tbody tr td, thead tr th {
        font-size: 14px;
    }

    thead tr th {
        font-weight: bold;
    }
}

.btn {
    font-size: 13px;
    cursor: pointer;

    &.btn-sm {
        font-size: 10px;
    }

    &.btn-xs {
        font-size: 10px;
        padding: 3px 5px;
    }

    &.btn-white {
        color: #000 !important;
    }
}

.panel.panel-default {
    background: #fff;
    border: none;
    border-radius: 0;

    .panel-heading {
        font-size: 14px;
        font-weight: 600;
        background-color: #fff;
        border-top: 3px solid #e7eaec;
        border-bottom: 1px solid #e7eaec;
        border-radius: 0;
        color: inherit;
        margin-bottom: 0;
        padding: 14px 15px 7px;
        min-height: 48px;
    }

    .panel-body {
        padding: 15px;
    }

    .panel-footer {
        background: #fbfafa;
    }
}