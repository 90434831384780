.deal-content-container {
    padding: 15px;

    .deal-content {
        background: #ffff;
        border: 1px solid #e7eaec;
        padding: 15px;

        &.no-padding {
            padding: 0px;
        }

        table.table-deal-positions {
            thead tr th {
                border-top: none;
            }

            tbody tr {
                &.position {
                    cursor: pointer;

                    &:hover {
                        background: rgba(0, 0, 0, 0.02);

                        .position-remove-container {
                            opacity: 1;
                        }
                    }

                    .pos-price-change {
                        opacity: 0.8;

                        span.price-change-value {
                            text-decoration: line-through;
                        }
                    }

                    .position-remove-container {
                        opacity: 0;
                        transition: all 150ms;
                    }

                    .deal-group-value {
                        &.changed-group-value {
                            opacity: 0.8;

                            span.value {
                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dealtool-positions-selector {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    ul.dealtool-positions-categories {
        list-style: none;
        padding: 0px;
        margin: 0px;
        background: #6c757d;

        &:after {
            clear: both;
            content: "";
            display: block;
        }

        li {
            float: left;
            padding: 5px 10px;
            font-size: 16px;
            color: #fff;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }

            &.active {
                background: #1c84c6;
            }

            &.plain-category-element {
                background: transparent;
                cursor: default;

                &:hover {
                    background: transparent;
                }
            }
        }
    }

    .dealtool-positions-wrapper {
        display: flex;
        flex: 1;
        overflow: auto;

        .dealtool-edit-position-no-pos {
            text-align: center;
            padding: 30px;
            font-size: 26px;
        }

        .dealtool-positions-list {
            width: 350px;
            border-right: 1px solid #ddd;
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: auto;
            float: left;

            li {
                padding: 15px;
                padding-right: 55px;
                border-bottom: 1px solid #eee;
                position: relative;
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                }

                &.active {
                    background: rgba(0, 0, 0, 0.1);
                }

                &.selected {
                    border-left: 8px solid #1c84c6;

                    .badge.badge-secondary {
                        background-color: #007bff;
                    }
                }
            }
        }

        .dealtool-positions-container {
            float: left;
            width: calc(100% - 350px);
            overflow: auto;
        }

        &:after {
            clear: both;
            content: "";
            display: block;
        }
    }
}

.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .dealtool-positions-selector .modal-title {
    margin: 0px;
}

.dealtool-edit-position {
    .item-header {
        background: rgba(0, 0, 0, 0.05);
        padding: 15px;
        font-size: 22px;
        font-weight: 300;
    }

    .dealtool-edit-position-container {
        padding: 15px;

        .dealtool-materials-table {
            border: 1px solid #e7eaec;
            margin: 0px;

            thead tr th {
                padding: 5px;
                padding-top: 10px;
            }

            tbody tr td {
                padding: 5px;
                height: 40px;
                vertical-align: middle;

                &.component {
                    padding: 10px 5px;
                    font-weight: bold;
                }

                select.form-control {
                    height: auto;
                    padding: 3px 10px;
                }
            }
        }
    }
}

.nav.group-info-text-nav {
    margin-left: -15px;
    border-top: 1px solid #eee;

    .nav-item button.nav-link {
        background: transparent;
        display: block;
        padding: 0.5rem 1rem;
        color: #0056b3;
        border: none;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        width: 100%;
        text-align: left;

        &:hover {
            background: rgba(0, 0, 0, 0.02);
        }

        &.active {
            font-weight: bold;
            color: #fff;
            background: #1c84c6;
        }
    }
}

.dealtool-infotext-selector {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .dealtool-infotext-selector-bg {
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        position: fixed;
        z-index: 1001;
    }

    .dealtool-infotext-container {
        width: 50%;
        max-width: 600px;
        height: 100%;
        background: #fff;
        top: 0px;
        right: 0px;
        position: fixed;
        overflow: auto;
        z-index: 1002;
        padding-top: 55px;

        .dealtool-infotext-title {
            color: #fff;
            background: #1c84c6;
            font-size: 16px;
            padding: 15px;
            height: 55px;
            position: fixed;
            top: 0px;
            width: 50%;
            max-width: 600px;
            box-sizing: border-box;

            .dealtool-close {
                float: right;
                cursor: pointer;
                font-size: 18px;
            }
        }

        .dealtool-infotext-list {
            padding: 15px;

            .dealtool-infotext-selector-text {
                margin-bottom: 10px;
                padding: 10px;
                border: 1px solid #eee;
                cursor: pointer;

                &:hover {
                    background: rgba(0, 0, 0, 0.02);
                }

                &.active {
                    color: #fff;
                    background: #1c84c6;
                }
            }
        }
    }
}

.position-group-infos {
    margin-left: -5px;
    margin-right: -5px;

    &:after {
        clear: both;
        content: "";
        display: block;
    }

    .group-info-col {
        width: 50%;
        float: left;
        padding: 0px 5px;
    }

    &.three-or-more {
        .group-info-col {
            width: 33.3333333%;
        }
    }
}

.quick-value-editor {
    cursor: pointer;
    position: relative;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 3px;

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
    }

    &.position-right {
        right: -5px;
    }

    &.negativ-margin {
        top: -5px;
        margin-bottom: -5px;
        line-height: 14px;
    }

    .dealtool-quick-value-bg {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.6);
        z-index: 251;
        cursor: default;
    }

    .dealtool-quick-value-editor {
        position: absolute;
        cursor: default;
        width: 300px;
        top: 0px;
        right: 0px;
        background: #fff;
        text-align: left;
        padding: 10px;
        z-index: 252;
        border-radius: 5px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

        .close-btn {
            float: right;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        label {
            font-weight: bold;
        }
    }
}
