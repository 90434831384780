.list-view {
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .list-item {
        display: block;
        padding: 10px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.deal-options {
    .deal-options-title {
        text-align: center;
        font-size: 16px;
        padding: 15px;
    }

    ul.deal-options-menu {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
            padding: 8px 15px;
            margin: 0px;
            border-top: 1px solid #eee;
            text-align: center;
            cursor: pointer;

            svg {
                margin-right: 5px;
                opacity: 0.7;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.05);
            }
        }
    }
}
