.main-sidebar {
	width: 280px;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	background: #fff;
	z-index: 1500;
	margin-left: -280px;
	transition: all 200ms cubic-bezier(.02, .01, .47, 1);

	ul.menu {
		list-style: none;
		padding: 0px;
		margin: 0px;
		padding-top: 5px;

		li {
			display: block;
			padding: 0px;
			margin: 0px;

			a {
				display: block;
				color: $color_text;
				padding: 10px 15px;

				&:hover {
					background: rgba(0,0,0,0.05);
				}

				svg {
					margin-right: 5px;
					font-size: 16px;
					position: absolute;
					margin-top: 2px;
				}

				span {
					display: block;
					padding-left: 30px;
				}
			}

			&.divider {
				margin-top: 4px;
				margin-bottom: 4px;
				height: 1px;
				width: 100%;
				background: #eee;
			}
		}
	}

	.sidebar-header {
		height: 100px;
		background: #eaeaea;
		position: relative;

		.hideSidebarBtn {
			position: absolute;
			top: 0px;
			right: 0px;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			padding: 5px 10px;
			cursor: pointer;
		}

		.sidebar-content {
			position: absolute;
			bottom: 0px;
			padding: 15px;

			.username {
				font-size: 14px;
				font-weight: bold;
			}

			.email {
				font-size: 12px;
			}
		}
	}

	&.opened {
		margin-left: 0px;
		box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
	}
}