.dealPositionSelector {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 3000;
	background: #fff;

	.selectorContent {
		padding: 15px;
	}

	.selector-nav {
		background: $color_main;
		overflow: auto;

		.selector-nav-title {
			padding: 10px 15px;
			padding-bottom: 10px;
			color: #fff;
			float: left;
			border-bottom: 4px solid $color_main;
			cursor: pointer;

			&.active {
				border-bottom: 4px solid $color_highlight;
			}

			@media(max-width: 767px) {
				margin-bottom: 3px;
			}
		}
	}

	.selector-list {
		overflow: hidden;

		.selector-list-container {
			width: 45%;
			float: left;
			overflow: auto;
			height: 100%;

			@media(max-width: 767px) {
				width: 100%;
			}

			.selector-item {
				padding: 20px;
				border-bottom: 1px solid rgba(0,0,0,0.1);
				border-left: 8px solid transparent;
				cursor: pointer;

				&:hover {
					background: rgba(0,0,0,0.05);
				}

				&.active {
					background: rgba(0,0,0,0.1);
				}

				&.in-deal {
					border-left-color: $color_highlight;
					font-weight: bold;
				}
			}
		}

		.selector-current-item {
			width: 55%;
			height: 100%;
			overflow: auto;
			float: left;
			border-left: 1px solid rgba(0,0,0,0.1);
			background: #f3f3f4;

			@media(max-width: 767px) {
				display: none;

				&.has-item {
					width: 100%;
					display: block;
					position: absolute;
					top: 0px;
					left: 0px;
					height: 100%;
				}
			}

			.selector-current-item-content {
				padding: 20px;
			}
		}
	}
}