.textSelector {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 999991;

    .textSelectorBg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 999992;
    }

    .textSelectorWrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 50%;
        height: 100%;
        background: #fff;
        z-index: 999993;
        text-align: left;

        .textSelectorTitle {
            background: $color_main;
            color: #fff;
            font-weight: bold;
            padding: 15px;
            font-size: 16px;
            position: relative;
            z-index: 999994;
        }

        .textSelectorContent {
            padding: 15px;
            overflow: auto;
            height: 100%;
            position: absolute;
            top: 0px;
            padding-top: 67px;
            box-sizing: border-box;
            width: 100%;

            .textElement {
                border: 1px solid #eee;
                padding: 10px;
                margin-bottom: 10px;
                cursor: pointer;

                &.selected {
                    background: #2bbbad;
                    color: #fff;
                }
            }
        }
    }
}
