.dealEditPosition {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 3000;
	background: #f3f3f4;

	.dealEditPositionContent {
		padding: 15px;
	}
}

.editPositionContainer {
	position: relative;

	.positionEditSaveBtns {
		&.fixed-top {
			position: fixed;
			z-index: 3050;
			padding: 5px;
			background: #fff;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
}