.dealActionToolbar {
	&.fixed-top {
		position: fixed !important;
		top: 46px;
		left: 0px;
		width: 100%;
		z-index: 5;
		background: #fff;
		padding: 3px;
		border-bottom: 1px solid #ddd;
		box-shadow: 0 0 10px rgba(0,0,0,0.3);
	}
}