.dealEditFPE {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 3000;
	background: #f3f3f4;

	.dealEditFPEContent {
		padding: 15px;
	}
}