@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

$color_text: #676a6c;
$color_main: #2f4050;
$color_highlight: #1ab394;

body, html {
	height: 100%;
}

body {
	background: #fff;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	padding: 0px;
	margin: 0px;
	color: #676a6c;
	overscroll-behavior: contain;
}

input, select, button, textarea {
	&.form-control {
		font-size: 13px;
	}
}

.header {
	background: $color_main;
	line-height: 30px;
	position: relative;
    // box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1000;
    height: 64px;
	padding: 15px;

	img.logo {
		margin: auto;
		height: 30px;
		display: block;
		cursor: pointer;
	}

	.sidebarToggle {
		position: absolute;
		left: 15px;
		top: 15px;
		color: #fff;
		font-weight: bold;
		cursor: pointer;
		height: 45px;
		width: 45px;
		margin-top: 3px;
		margin-left: 5px;
	}
}

.pageWrapperContainer {
	min-height: 100vh;
	padding-top: 64px;
	background: #f3f3f4;

	.updateNotification {
		text-align: center;
		padding: 15px;
		background: #FDD01D;
		font-weight: bold;
		cursor: pointer;

		i.fa {
			margin-right: 5px;
		}
	}
}

.footer {
	font-size: 12px;
	padding: 5px;
	background: #fff;
	border-top: 1px solid #eee;
	width: 100%;
	margin-top: 30px;
}

.content {
	padding: 15px;

	h1 {
		span {
			font-size: 20px;
		}
	}

	.headerAction {
		position: fixed;
		top: 7px;
		right: 7px;
		z-index: 1100;
	}
}