.scroll-bar {
    background: #fff;
    border-bottom: 1px solid #eee;

    .scroll-bar-content {
        padding: 5px 15px;
    }

    .visible-scrolled {
        // display: none;
    }

    &.scrolled {
        // position: fixed;
        // top: 60px;
        // z-index: 180;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);

        // .scroll-bar-content {
        //     padding-top: 10px;
        // }

        // .visible-scrolled {
        //     display: block;
        // }

        // .hidden-scrolled {
        //     display: none;
        // }
    }
}

.dealtool {
    padding-top: 64px;

    .deal-positions-toolbar {
        .btn-group {
            margin-top: -7px;

            button.btn.btn-xs.btn-light {
                color: #555 !important;
                padding: 7px 10px;
            }
        }
    }

    #page-scroll-bar {
        width: 100% !important;
        position: fixed;
        z-index: 1000;
        top: 64px;
        box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);
    }

    .infotext-types-btns {
        .btn.btn-light {
            color: #555 !important;
        }
    }
}

.modal-title {
    padding: 15px;
}
