@import "node_modules/react-confirm-alert/src/react-confirm-alert";

.react-confirm-alert-overlay {
    -webkit-animation: react-confirm-alert-fadeIn 200ms 150ms forwards;
    -moz-animation: react-confirm-alert-fadeIn 200ms 150ms forwards;
    -o-animation: react-confirm-alert-fadeIn 200ms 150ms forwards;
    animation: react-confirm-alert-fadeIn 200ms 150ms forwards;
    background: rgba(0,0,0,0.6);
    z-index: 3700;

    .react-confirm-alert {
        border-radius: 0px;
        border: 1px solid #eee;
        box-shadow: none;
        font-family: 'Open Sans', sans-serif;
        padding: 20px;

        h1 {
            font-size: 24px;
            margin-bottom: 15px;
        }

        h3 {
            font-size: 14px;
        }

        .react-confirm-alert-button-group {
            button {
                background-image: none;
                border-bottom-color: rgb(255,255,255);
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
                border-bottom-style: none;
                border-bottom-width: 0px;
                border-image-outset: 0px;
                border-image-repeat: stretch;
                border-image-slice: 100%;
                border-image-source: none;
                border-image-width: 1;
                border-left-color: rgb(255,255,255);
                border-left-style: none;
                border-left-width: 0px;
                border-right-color: rgb(255,255,255);
                border-right-style: none;
                border-right-width: 0px;
                border-top-color: rgb(255,255,255);
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
                border-top-style: none;
                border-top-width: 0px;
                box-shadow: rgba(0,0,0,0.16) 0px 2px 5px 0px,rgba(0,0,0,0.12) 0px 2px 10px 0px;
                box-sizing: border-box;
                color: rgb(255,255,255);
                cursor: pointer;
                display: block;
                font-family: "Open Sans",sans-serif;
                font-size: 13px;
                font-weight: normal;
                height: 35px;
                line-height: 18.5714px;
                margin-bottom: 0px;
                margin-left: 0px;
                margin-right: 5px;
                margin-top: 0px;
                padding-bottom: 8.5px;
                padding-left: 21.3px;
                padding-right: 21.3px;
                padding-top: 8.5px;
                text-align: center;
                text-decoration-color: rgb(255,255,255);
                text-decoration-line: none;
                text-decoration-style: solid;
                text-size-adjust: 100%;
                text-transform: uppercase;
                touch-action: manipulation;
                transition-delay: 0s;
                transition-duration: 0.2s;
                transition-property: all;
                transition-timing-function: ease-out;
                user-select: none;
                vertical-align: middle;
                white-space: normal;
                width: 482px;
                word-wrap: break-word;
                -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
        }
    }
}