.page-loader {
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	z-index: 99999;
	background: rgba(0,0,0,0.6);

	.page-loader-container {
		position: fixed;
		top: 50%;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;

		.page-loader-msg {
			width: 300px;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
			background: #fff;
			padding: 30px;
			border-radius: 3px;
			font-size: 14px;
			line-height: 14px;
			margin: auto;
			margin-top: -60px;
		}
	}
}